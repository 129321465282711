import React from "react";

type AnimateOnScrollProps = {
  className?: string;
  transition: string;
  delay?: string;
  ease?: string;
  children: React.ReactElement | React.ReactElement[];
};

function AnimateOnScroll({
  className,
  transition,
  delay,
  ease,
  children,
}: AnimateOnScrollProps) {
  return (
    <div
      className={className}
      data-sal={transition}
      data-sal-delay={delay || 0}
      data-sal-easing={ease || "ease"}
    >
      {children}
    </div>
  );
}

export default AnimateOnScroll;
